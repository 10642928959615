<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
// import axios from "axios";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  setup(){
    // const server   =  process.env.VUE_APP_SERVER;
    const router   = useRouter();
    const store    = useStore();
    const email    = ref("");
    const password = ref("");
    const error    = ref(null); 

    const login = () => {
      store.dispatch("login", {
        email    : email.value,
        password : password.value
      }).then(() => {
        router.push({name : "IndicesAdmin"});
      }).catch(() => {
        error.value = true;
      });
    }

    return {
      email,
      password,
      login,
      error
    }
  }
}
</script>
<template>
<section>
  <div class="container">
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
      <h2 class="mb-3">Acceder al administrador de Observadatos</h2>
  <form v-on:submit.prevent="login">
    <p class="error ob_alert danger" v-if="error">Tus credenciales no son correctas</p>
    <p>
      <label>correo:</label>
      <input type="email" name="email" v-model="email" required />
    </p>
    <p>
      <label>contraseña:</label>
      <input type="password" name="password" v-model="password" required />
    </p>
    <p>
      <input type="submit" value="Acceder" />
    </p>
  </form>
    </div>
    </div>
  </div>
</section>
</template>